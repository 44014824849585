import React, { Component,createRef } from 'react';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import { process } from '@progress/kendo-data-query';
import Snackbar from '@material-ui/core/Snackbar';
import '../reports/reports.scss'
import Table from 'react-bootstrap/Table';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { withTranslation } from 'react-i18next';
import common_function from '../common_function';
import Calendar from 'react-calendar/dist/entry.nostyle'
import { LineWeight } from '@material-ui/icons';
import Navbar from '../../Config/Navbar';
import { BrowserRouter as Router, Link, } from "react-router-dom";
import Amplify, { Analytics, selectInput } from 'aws-amplify';
import awswebapp from '../../aws-exports';
const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };

class Scheduler_report extends Component {
    constructor(props) {
        super(props);
        this.inputRef = createRef();
        this.state = {
          disable : false,
          date: "",
          startDate: new Date(),
          endDate: new Date(),
          date1: new Date(),
          startDate1: new Date(),
          endDate1: new Date(),
          sendStartTime : "00:00",
          sendEndTime : "23:59", 
          showStartTime : false,
          showEndTime : false,
    
          reportTypeList : [],
          report_type : "",
    
          option_type : ["Vehicle Based Report","Group Based Report"],
          choice_value : "Vehicle Based Report",
          vehicle_name : "",
          serial_number_is : [],
          checkbox_value: [],
          showTable: true,
    
          groupDataList: [],
          vlcGroupDataList: [],
          initial_stage : true,
          counter: 0,
          checkbox_value_forGroup: [],
    
          reportFormatList : ["EXCEL","CSV"],
          reportFormatType : "",
    
    
          periodTypeList : [],
          period_type : "",

          circleTypeList: [],
          circle_type : "",
         

          areaTypeList: [],
          area_type : "",
    
          sendEmail : false,
          emailaddress : "",
    
          openAlert: false,
          message: '',
          sevenDayFlag : true,
          showCalender : false,
    
          recentReportList : [],
          nextCount : 5,
          list : [],
          serialNoList : [],
          allchecked : true,

          driverNameList : "",
          checkbox_value_forDriver: [],
          flag : true,

          checked : true,
          changeStatus : "Download",
        }
      }
    
      componentDidMount() {
        Amplify.configure(awswebapp);
        this.getReportType();
        this.getPeriodTypes();
        this.getVehicleList();
        this.getVehicleListForGroup();
        this.getEmailAddress();
        this.setIntervalgetRecentReports();

        var navBarHeight = $('.navBar').outerHeight();
        $('.scheduler_report_div').css({
          paddingTop: navBarHeight + 60,
        })
      }

      componentWillUnmount() {
        clearInterval(this.interval)
      }

    
      getReportType = () => {
        $('#processing').show()
        ApiUrlCall.getReportType().then(response => {
          if (response.data.response_code === 200 && response.data.is_success === true) {
            //console.log(response)
            $('#processing').hide()
            this.setState ({
                reportTypeList : response.data.data.report_types_list,
            })
            if (this.props.location.state !== undefined){
              this.setState({
                 report_type : this.props.location.state.id,
                 disable : true,
              })
            }
          }     
        }).catch(error => {
          console.log(error, "error while fetching report types");
        })
      }

      
    
      reportType =(e) => {
        if (e.currentTarget.value == 0){
          this.setState({
            disable : false,
            report_type : "",
          })
        }else if(e.currentTarget.value == 20){
            this.setState({
                disable : false,
                report_type : e.currentTarget.value,
          })
          this.getDriverList()
        }else{
          this.setState({
            report_type : e.currentTarget.value,
            disable : true,
          })
          this.getCircleTypes();
          //this.getVehicleList();

        }
        
      }
    
      returnReportType = () => { 
        if (localStorage.getItem('group') == 28){        
        var reportTypeList = [{id: 18, report_type: 'Summary Report'},{id: 21, report_type: 'Journeys Report'},{id: 22, report_type: 'Trips Report'},{id: 24, report_type: 'Stoppage Report'}]
        var report_type_list = reportTypeList.map(report_type_list => {
          return (<option key={report_type_list.id} value={report_type_list.id}>{report_type_list.report_type}</option> )
         })
          return (<Grid item xs={6}>              
          <select id={'report_'} value={this.state.report_type} className="pudo-filter journey_input" style={{ width: "100%" }}
              onChange={(e) => {
                  for (const option of document.getElementById('report_')) {
                    if (option.value === e.currentTarget.value){
                      option.selected = true
                    }else{
                      option.selected = false                            
                    } 
                  }
                  this.reportType(e)
              }}
              >
               <option value={0}>Select Report Type</option> 
              {report_type_list}
          </select>
          </Grid> )
}
if (localStorage.getItem('group') != 30){        
  var reportTypeList = [ {id: 32, report_type: 'DG Aggregation Report'},{id: 35, report_type: 'DG Unique Report'},{id: 18, report_type: 'Summary Report'},{id: 19, report_type: 'Vehicles Report'},{id: 20, report_type: 'Drivers Report'},{id: 21, report_type: 'Journeys Report'},{id: 22, report_type: 'Trips Report'},
  {id: 23, report_type: 'Travel Report'},{id: 24, report_type: 'Stoppage Report'},{id: 25, report_type: 'Idling Report'},{id: 26, report_type: 'Inactive Summary Report'},{id: 27, report_type: 'Fuel Report'},
  {id: 28, report_type: 'Tracker Mapping Report'},{id: 29, report_type: 'Current Location of Vehicle Report (L)'},{id: 30, report_type: 'Vehicle Movement 24 Hrs Report  (L)'},
  {id: 31, report_type: 'Vehicle Idle Report (L)'},{id: 33, report_type: 'Device Health Report (L)'},{id: 34, report_type: 'Vehicle Parked From > 24 Hrs(L)'},
  ]
  var report_type_list = reportTypeList.map(report_type_list => {
    return (<option key={report_type_list.id} value={report_type_list.id}>{report_type_list.report_type}</option> )
   })
    return (<Grid item xs={6}>              
    <select id={'report_'} value={this.state.report_type} className="pudo-filter journey_input" style={{ width: "100%" }}
        onChange={(e) => {
            for (const option of document.getElementById('report_')) {
              if (option.value === e.currentTarget.value){
                option.selected = true
              }else{
                option.selected = false                            
              } 
            }
            this.reportType(e)
        }}
        >
         <option value={0}>Select Report Type</option> 
        {report_type_list}
    </select>
    </Grid> )
}    
        else{

        var {reportTypeList} = this.state   
         var report_type_list = reportTypeList.map(report_type_list => {
             return (<option key={report_type_list.id} value={report_type_list.id}>{report_type_list.report_type}</option> )
            })
           return (<Grid item xs={6}>              
           <select id={'report_'} value={this.state.report_type} className="pudo-filter journey_input" style={{ width: "100%" }}
               onChange={(e) => {
                   for (const option of document.getElementById('report_')) {
                     if (option.value === e.currentTarget.value){
                       option.selected = true
                     }else{
                       option.selected = false                            
                     } 
                   }
                   this.reportType(e)
               }}
               >
                <option value={0}>Select Report Type</option> 
               {report_type_list}
           </select>
           </Grid> )
        }
        }

    
    
    /////////////////////////////////Period Section//////////////////////////////////////////
    
    
    
      getPeriodTypes = () => {
        var data = {}
        $('#processing').show()
        ApiUrlCall.get_period_types(data).then(response => {
          if (response.data.response_code === 200 && response.data.is_success === true) {
            $('#processing').hide()
            //console.log(response.data.data.period_types_list,"period_type")
            this.setState({
              periodTypeList: response.data.data.period_types_list,
              //circleTypeList : [{id: 1, name: 'RAJASTHAN'},{id: 2, name: 'MAHARASHTRA'}]
            })
          }
        }).catch(error => {
          console.log(error, "error while fetching period types");
        })
      }

      getCircleTypes = () => {
        var data = {}
        $('#processing').show()
        ApiUrlCall.get_circle_types(data).then(response => {
          if (response.data.response_code === 200 && response.data.is_success === true) {
            $('#processing').hide()
            //console.log(response.data.data.circle_types_list,"circle_type")
            this.setState({
              circleTypeList : response.data.data.circle_types_list,
          })
          this.getVehicleList();
        //   setTimeout(() => {
        //     this.getAreaTypes();
        // }, 3000); // 10000 milliseconds = 10 seconds
         }
        }).catch(error => {
          console.log(error, "error while fetching circle types");
        })
      }
    

      getAreaTypes = () => {
        var data = {circle_type:this.state.circle_type}
       // var data = {circle_type:'Rajasthan'}
        $('#processing').show()
        ApiUrlCall.get_area_types(data).then(response => {
          if (response.data.response_code === 200 && response.data.is_success === true) {
            $('#processing').hide()
            //console.log(response.data.data.area_types_list,"area_type")
            this.setState({
              areaTypeList : response.data.data.area_types_list,
              
          })
          //this.getVehicleList();

        }
        }).catch(error => {
          console.log(error, "error while fetching circle types");
        })
      }

      periodType =(e) => {
        this.setState({
          period_type:e.currentTarget.value
        }, () => {
          this.showDate()
        })
      }
      circleType =(e) => {
        this.setState({
          circle_type:e.currentTarget.value
        }, () => {
          //this.showDate()
          this.getVehicleList();
          this.getAreaTypes();

        })
      }

      areaType =(e) => {
        this.setState({
          area_type:e.currentTarget.value
        }, () => {
          //this.showDate()

          this.getVehicleList();
        })
      }
    
      showDate = () => {
        var dateObj = new Date()
        if(this.state.period_type == "TODAY") {
          var start_time = CommonFunction.createServerDateTime(dateObj,this.state.sendStartTime)
          var end_time = CommonFunction.createServerDateTime(dateObj, this.state.sendEndTime)
          this.state.date = ""
          this.state.startDate = start_time
          this.state.endDate = end_time
          this.state.showCalender = false
          //console.log(start_time,end_time,"START DATE & END DATE")
        }
        else if(this.state.period_type == "YESTERDAY") {
          var date = dateObj.setDate(dateObj.getDate() - 1)
          var start_time = CommonFunction.createServerDateTime(new Date(date),this.state.sendStartTime)
          var end_time = CommonFunction.createServerDateTime(new Date(date), this.state.sendEndTime)
          this.state.date = (dateObj.toLocaleDateString('en-US', DATE_OPTIONS)).toString()
          this.state.startDate = start_time
          this.state.endDate = end_time
          this.state.showCalender = false
          //console.log(start_time,end_time,"START DATE & END DATE")
        }
        else if(this.state.period_type == "LAST 7 DAYS") {
          var start_date = dateObj.setDate(dateObj.getDate() - 7)
          var end_date = dateObj.setDate(dateObj.getDate() + 6)
          var start_time = CommonFunction.createServerDateTime(new Date(start_date),this.state.sendStartTime)
          var end_time = CommonFunction.createServerDateTime(new Date(end_date), this.state.sendEndTime)
          var a = (new Date(start_date).toLocaleDateString('en-US', DATE_OPTIONS)).toString()
          var b = (new Date(end_date).toLocaleDateString('en-US', DATE_OPTIONS)).toString()
          this.state.date = a +" to "+b
          this.state.startDate = start_time
          this.state.endDate = end_time
          this.state.showCalender = false
          //console.log(start_time,end_time,"START DATE & END DATE")
        }
        else if(this.state.period_type == "LAST MONTH") {
          var firstDay_month = (new Date(dateObj.getFullYear(), dateObj.getMonth()-1, 1)).toLocaleDateString('en-US', DATE_OPTIONS);
          var lastDay_month = (new Date(dateObj.getFullYear(), (dateObj.getMonth()-1) + 1, 0)).toLocaleDateString('en-US', DATE_OPTIONS);
          var start_time = CommonFunction.createServerDateTime(new Date(firstDay_month),this.state.sendStartTime)
          var end_time = CommonFunction.createServerDateTime(new Date(lastDay_month), this.state.sendEndTime)
          var a = (new Date(firstDay_month).toLocaleDateString('en-US', DATE_OPTIONS)).toString()
          var b = (new Date(lastDay_month).toLocaleDateString('en-US', DATE_OPTIONS)).toString()
          this.state.date = a +" to "+b
          this.state.startDate = start_time
          this.state.endDate = end_time
          this.state.showCalender = false
          //console.log(start_time,end_time,"START DATE & END DATE")
        }
        else if(this.state.period_type == "CUSTOM") {
          this.setState({
            showCalender : true,
            // date : new Date(),
            // startDate: new Date(),
            // endDate: new Date(),
          })
        }
        this.forceUpdate()
    
      }
    
      ////////////////////////Calender /////////////////////////////////
      dateFormate = (rdate) => {
        var date = new Date(rdate);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        if (day < 10) {
          day = '0' + day;
        }
        if (month < 10) {
          month = '0' + month;
        }
        var convertDate = day + '/' + month + '/' + year
        return convertDate
      }
    
      showStartDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
        $('#end_date_cal').slideUp()
        this.setState({
          showStartTime : false,
          showEndTime : false,
        })
      }
    
    

      StartdateClicked = date => {
        var {endDate1} = this.state
        var isoStartDate = date
        isoStartDate.setHours(0,0,0,0)       
        //this.setState({ startDate1: isoStartDate })
        //$('#start_date_cal').slideUp(200)
        //$('.react-calendar').slideUp(200)
        this.setState({ startDate1: isoStartDate }, () => {
          const calendarElement = $('.calendar_parent');
          if (calendarElement.length > 0) {
            calendarElement.stop(true, true).slideUp(200, function() {
              //calendarElement.css('display', 'none')
            }); // Ensure any ongoing animations are stopped
          } else {
            console.error('#start_date_cal element not found');
          }
        });
      }

    
      showEndDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
        $('#start_date_cal').slideUp()
        this.setState({
          showStartTime : false,
          showEndTime : false,
        })
      }
    
      
      EnddateClicked = date => {
        var {startDate1} = this.state
        var isoEndDate = date
        isoEndDate.setHours(23,59,59,0)   
        var Difference_In_Time = isoEndDate.getTime() - this.state.startDate1.getTime();
        var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
        if(Difference_In_Days > 90){ 
          $('#processing').hide()     
          this.setState({
            sevenDayFlag : false,   
            openAlert : true,
            message : "Not allowed to select more than 90 days"     
          })     
          return 
        }else{
          this.setState({
            sevenDayFlag : true
          })
        } 
        // console.log('props value', CommonFunction.getTime(date))
        this.setState({ endDate1: isoEndDate }, () => {
          const calendarElement = $('.calendar_parent');
          if (calendarElement.length > 0) {
            calendarElement.stop(true, true).slideUp(200, function() {
              //calendarElement.css('display', 'none')
            }); // Ensure any ongoing animations are stopped
          } else {
            console.error('#end_date_cal element not found');
          }
        });
      }
    
      showStartCalender = () => {
        var minDate = new Date(); 
        minDate.setDate(minDate.getDate() - 90);
        //console.log("this.state.date",this.state.date)
        if (this.state.showCalender) {
          return(
            <Grid item xs = {2} style = {{ marginRight : "100px" }}>
            <p>Start Date</p>
            <div className="input_div" onClick={this.showStartDate}>
              <input type="text" placeholder="Start Date" id="start_date" value={this.dateFormate(this.state.startDate1)} className="journey_input" disabled></input>
              <img src="images/fill-111.png" className="vd_journey_icon"></img>
            </div>
            <div className="calendar_parent" id="start_date_cal">
              <Calendar
                selectRange={false}
                onChange={this.StartdateClicked}
                value={this.state.date1}
                maxDate={this.state.date1}
                minDate={minDate}
              />
            </div>
            </Grid>
          )
        }
        
      }
    
      showEndCalender = () => {
        var minDate = new Date(); 
        minDate.setDate(minDate.getDate() - 90);
        //console.log("this.state.date",this.state.date)
        if (this.state.showCalender){
          return(
            <Grid item xs = {2}>
            <p>End Date</p>
            <div className="input_div" onClick={this.showEndDate}>
              <input type="text" placeholder="End Date" id="end_date" value={this.dateFormate(this.state.endDate1)} className="journey_input" disabled></input>
              <img src="images/fill-111.png" className="vd_journey_icon"></img>
            </div>
            <div className="calendar_parent" id="end_date_cal">
              <Calendar
                selectRange={false}
                onChange={this.EnddateClicked}
                value={this.state.date1}
                maxDate={this.state.date1}
                minDate={minDate}
              />
            </div>
            </Grid>   
          )
        }
      }
     /////////////////////////////////////////////////////////////
      returnPeriodType = () => { 
      var {periodTypeList} = this.state   
       var period_type_list = periodTypeList.map(period_type_list => {
           return (<option key={period_type_list.id} value={period_type_list.name}>{period_type_list.name}</option> )
          })
         return (<Grid item xs={6}>              
         <select id={'period_'} value={this.state.period_type} className="pudo-filter journey_input" style={{ width: "100%" }}
             onChange={(e) => {
                 for (const option of document.getElementById('period_')) {
                   if (option.value === e.currentTarget.value){
                     option.selected = true
                   }else{
                     option.selected = false                            
                   } 
                 }
                 this.periodType(e)
             }}
             >
             <option value={0}>Select Period Type</option>
             {period_type_list}
         </select>
         </Grid> )
      }
      returnCircleType = () => { 
        var {circleTypeList} = this.state   
         var circle_type_list = circleTypeList.map(circle_type_list => {
             return (<option key={circle_type_list.id} value={circle_type_list.name}>{circle_type_list.name}</option> )
            })
           return (<Grid item xs={6}>              
           <select id={'circle_'} value={this.state.circle_type} className="pudo-filter journey_input" style={{ width: "100%" }}
               onChange={(e) => {
                   for (const option of document.getElementById('circle_')) {
                     if (option.value === e.currentTarget.value){
                       option.selected = true
                     }else{
                       option.selected = false                            
                     } 
                   }
                   this.circleType(e)
               }}
               >
               <option value={0}>All</option>
               {circle_type_list}
           </select>
           </Grid> )
        }
    


        returnAreaType = () => { 
          var {areaTypeList} = this.state   
           var area_type_list = areaTypeList.map(area_type_list => {
               return (<option key={area_type_list.id} value={area_type_list.name}>{area_type_list.name}</option> )
              })
             return (<Grid item xs={6}>              
             <select id={'area_'} value={this.state.area_type} className="pudo-filter journey_input" style={{ width: "100%" }}
                 onChange={(e) => {
                     for (const option of document.getElementById('area_')) {
                       if (option.value === e.currentTarget.value){
                         option.selected = true
                       }else{
                         option.selected = false                            
                       } 
                     }
                     this.areaType(e)
                 }}
                 >
                 <option value={0}>All</option>
                 {area_type_list}
             </select>
             </Grid> )
          }
    ////////////////////////////////////////////////////////////////////
     
    
    ////////////////////////////Vehicle List //////////////////////////////
    
    hideAllDropDown = () => {
      $('#vehicles_select').parent().hide()
      $('#123').parent().hide()
      $('#456').parent().hide()
      $('#end_date_cal').hide()
      $('#start_date_cal').hide()
      this.setState({
        showStartTime : false,
        showEndTime : false,
      })
    }
    
    dropDownClick = (e) => {
      $('#vehicles_select').slideDown('fast');
      var dropdownwidth = e.currentTarget.offsetWidth
      var checklist = e.currentTarget.nextSibling
      $(checklist).css({
        width: dropdownwidth
      })
      $(checklist).slideToggle(300);
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    
    }
    
    // getVehicleList = () => {
    //   //var data = {}
    //   const data = {circle : this.state.circle_type,area:this.state.area_type}
    //   console.log(data,"circle data")
    //   ApiUrlCall.getVehcile_Name_latest(data).then(response => {
       
    //     if (response.data.response_code === 200 && response.data.is_success === true) {
    //       $('#all_vehicle').prop('checked', true)
    //       this.setState({
    //         vehicle_name: response.data.data,
    //         serial_number_is: response.data.data.serial_number
    //       },() => {
    //        this.state.vehicle_name.vehicle_list.map(serialNo => {
    //         if(serialNo.is_expired == false){
    //           this.state.serialNoList.push(serialNo.serial_number)
    //         }
    //        })
    //       })
    //        console.log(response.data.data, ">>>>>>>>>>>>",this.state.serialNoList)
    //     }
    //   }).catch(error => {
    //     console.log(error, "error while fetching vehicle list");
    //   })
    // }

    getVehicleList = () => {
      // Clear the serialNoList before adding new items
      this.setState({ serialNoList: [] }, () => {
        const data = { circle: this.state.circle_type, area: this.state.area_type };
        //console.log(data, "circle data");
    
        ApiUrlCall.getVehcile_Name_latest(data).then(response => {
          if (response.data.response_code === 200 && response.data.is_success === true) {
            $('#all_vehicle').prop('checked', true);
            
            let newSerialNoList = [];
    
            response.data.data.vehicle_list.map(serialNo => {
              if (serialNo.is_expired == false) {
                newSerialNoList.push(serialNo.serial_number);
              }
            });
    
            this.setState({
              vehicle_name: response.data.data,
              serial_number_is: response.data.data.serial_number,
              serialNoList: newSerialNoList
            });
    
            //console.log(response.data.data, ">>>>>>>>>>>>", this.state.serialNoList);
          }
        }).catch(error => {
          console.log(error, "error while fetching vehicle list");
        });
      });
    };
    
    
    onChange(e) {
      // current array of options
      this.setState({allchecked : false})
      const options = this.state.checkbox_value
      let index
      let {counter} = this.state
      $('#all_vehicle').prop('checked',false)    
  
      // check if the check box is checked or unchecked
      if (e.target.checked) {
        // add the numerical value of the checkbox to options array      
        options.push(+e.target.value)
        //console.log(options)
        this.forceUpdate()
    
        // $('#vehicles_select').slideUp(200)
    
      } else {
        // or remove the value from the unchecked checkbox from the array      
        index = options.indexOf(+e.target.value)
        
        options.splice(index, 1)
        this.forceUpdate()
      }
    
      // sort the array
      options.sort()
    
      // update the state with the new array of options
      // this.setState({ options: options })
    }
    
    allVehicle = (e) =>{
      if(e.currentTarget.checked){
        $(e.currentTarget).parent().parent().siblings().find('input').prop('checked',false)      
        this.setState({
          checkbox_value : [],
          allchecked : true,
        },() =>{
          //console.log(this.state.serialNoList,"all data")
        })
      }
    }
    
    
    /////////////////////////Vehicle List End ////////////////////////////////
    
    /////////////////////////Group List /////////////////////////////////
    getVehicleListForGroup = () => {
      var data = {}
      ApiUrlCall.ShowVehicleGroupList(JSON.stringify(data)).then(response => {
        if (response.data.response_code == 200 && response.data.is_success) {
          this.setState({
            groupDataList: response.data.data.group_data_list,
          })
          var data1 = []
          for (var i = 0; i < this.state.groupDataList.length; i++) {
            var obj = { groupName: this.state.groupDataList[i].group_name,serialNoList:[] }
            var vehicle_data = this.state.groupDataList[i].vehicle_data_list
            
            for (var j = 0; j < vehicle_data.length; j++) {
              obj.serialNoList.push(vehicle_data[j].serial_number)
            }          
            data1.push(obj)
          }
          this.setState({ vlcGroupDataList: data1, })
        }
      }).catch(error => {
        console.log('error while fetching vehicle group data', error)
      })
    }
    
    
    onChangeForGroup(e) {
      // current array of options
      const options = this.state.checkbox_value_forGroup
      let index
      let {counter} = this.state
    
      // check if the check box is checked or unchecked
      if (e.target.checked) {
        // add the numerical value of the checkbox to options array 
        const list = e.target.value.split(",")     
        //console.log(e.target.value)
        this.state.checkbox_value_forGroup.push(...list)
    
        // $('#vehicles_select').slideUp(200)
    
      } else {
        // or remove the value from the unchecked checkbox from the array      
        //index = options.indexOf(+e.target.value)
        const list = e.target.value.split(",")      
        //options.splice(index, 1)
        //this.forceUpdate()
        list.map(e =>{
          index = this.state.checkbox_value_forGroup.indexOf(e)
          this.state.checkbox_value_forGroup.splice(index,1) 
        })
      }
    
      // sort the array
      options.sort()
    
      // update the state with the new array of options
      // this.setState({ options: options })
    }
    
    
    ////////////////////////Group End /////////////////////////////////
    
    ///////////////////////Select Choice //////////////////////////////
    choiceChange(e){
      if (e.target.checked){
        if (e.target.value == "Vehicle Based Report"){
          $('#option2').prop('checked', false)
        }
        if (e.target.value == "Group Based Report"){
          $('#option1').prop('checked', false)
        }
         this.setState({
           choice_value : e.target.value
         })
      }
      else{
        this.setState({
          choice_value : "Select"
        })
      }
    }
    
    ////////////////////////End Choice///////////////////////////////
    
    //////////////////////////Select Format Start////////////////////
    
    reportFormatType =(e) => {
      this.setState({
        reportFormatType : e.currentTarget.value,
      })
    }
    
    returnFormatType = () => { 
      var {reportFormatList} = this.state
 
       var report_format_type_list = reportFormatList.map(report_format_type_list => {
           return (<option value={report_format_type_list}>{report_format_type_list}</option> )
          })
          //console.log(report_format_type_list,"report_format_type_list")
         return (<Grid item xs={6}>              
         <select id={'format_'} value={this.state.reportFormatType} className="pudo-filter journey_input" style={{ width: "100%" }}
             onChange={(e) => {
                 for (const option of document.getElementById('format_')) {
                   if (option.value === e.currentTarget.value){
                     option.selected = true
                   }else{
                     option.selected = false                            
                   } 
                 }
                 this.reportFormatType(e)
             }}
             >
              <option value={0}>Select Format Type</option>
             {report_format_type_list}
         </select>
         </Grid> )
      }
    
      getEmailAddress = () => {
        ApiUrlCall.getEmailAddress().then(response => {
          if (response.data.is_success === true) {
              this.setState({
                emailaddress : response.data.email_address
              })
          }
        }).catch(error => {
          console.log(error, "error while fetching email address");
        })
      }
    
      sendEmailto = (e) =>{
        if(e.currentTarget.checked){
          //$(e.currentTarget).parent().parent().siblings().find('input').prop('checked',false)      
          this.setState({
            sendEmail : true,
          })
        }
      }
    
    //////////////////////////Select Format End ////////////////////
    
    /////////////////////////Recent Format//////////////////////////
    getRecentReports = () => {
      //console.log("getRecentReport",this.state.nextCount)
      var sizeOfList = this.state.nextCount
      const data = {size : sizeOfList}
      ApiUrlCall.getRecentReport(data).then(response => {
        if (response.data.is_success === true && response.data.response_code === 200) {
            this.setState({
              recentReportList : response.data.data.recent_report_list,
              changeStatus : "Download"
              //nextCount : response.data.data.next_count,
              //list : [],
            },() => {
                //this.state.list = [...this.state.recentReportList]
                var list1 =[]
                this.state.recentReportList.map((array) => {
                  list1.push(array)      
                })
                this.setState({
                  list:list1,
                })
                if (this.state.flag){
                    this.setState({
                      nextCount : sizeOfList,
                      flag : false,
                    })
                }
                if(this.state.nextCount > response.data.data.total_count){
                    $("#button").hide()
                }
                this.showRecentReport()
              })
    
        }
      }).catch(error => {
        console.log(error, "error while fetching recent reports");
      })
    }
    
    setIntervalgetRecentReports = () => {
      this.getRecentReports()
      this.interval = setInterval(this.getRecentReports, 10000)
    }
    
    getRecentReportsonclick = () =>{
      //console.log("getRecentReportonclick")
      var sizeOfList = this.state.nextCount+5
      const data = {size : sizeOfList}
      ApiUrlCall.getRecentReport(data).then(response => {
        if (response.data.is_success === true && response.data.response_code === 200) {
            this.setState({
              recentReportList : response.data.data.recent_report_list,
              nextCount : sizeOfList,
              flag : true,
              //list : [],
            },() => {
              var list1 =[]
              this.state.recentReportList.map((array) => {
                list1.push(array)      
              })
              this.setState({
                list:list1,
              })
              this.showRecentReport()
              if(this.state.nextCount >= response.data.data.total_count){
                  this.setState({
                    nextCount : sizeOfList,
                  })
                  $("#button").hide()
              }
            })
    
        }
      }).catch(error => {
        console.log(error, "error while fetching recent reports");
      })
    }

    downloadClick = (request_time,report_type,format_type,period_type,file_name,expire_time,index,e) =>{
      var data = {
        request_time : request_time,
        report_type : report_type,
        period_type : period_type,
        format_type : format_type,
        file_name : file_name,
        expire_time : expire_time,
      }
      ApiUrlCall.sendDataByDownloadLink(data).then(response => {
        if (response.data.response_code === 200 && response.data.is_success === true) {
            this.getRecentReports()
            //this.showRecentReport()
            this.setState({
              changeStatus : "Generating Link",
            })
            setTimeout(() => {
              document.getElementById(index).click()
            }, 1000); 
            
        }     
      }).catch(error => {
        console.log(error, "error while clicking on download link");
      })
    }
    
    showRecentReport = () => {
      var rencentReport = this.state.list.map((rencentReport,index) => {
        var start_time = (new Date(rencentReport.report_start_time).toLocaleDateString('en-US', DATE_OPTIONS)).toString()
        var end_time = (new Date(rencentReport.report_end_time).toLocaleDateString('en-US', DATE_OPTIONS)).toString()
        var a = start_time+ " to "+end_time
        if (rencentReport.report_status === "Report has no data"){
          return(
            <div className="report_filter_div1" style={{backgroundColor: "#EDEBEB",marginTop : "10px"}}>
              <div style = {{marginRight: "50px" , marginLeft: "50px"}}>
                <Grid spacing={2} container>
                  <Grid item xs>
                     <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>{rencentReport.report_type}</p>
                        <p>{a}</p>
                     </Grid>
                     <Grid item xs>
                          <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Format</p>
                             {rencentReport.format_type}
                    </Grid>
                    <Grid item xs>
                         <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Requested Time</p>
                        {rencentReport.request_time}
                    </Grid>
                    <Grid item xs>
                       <p className="work_sans_regular16" style = {{fontWeight: 'bold',color : "red"}}>No Data Available</p>
                    </Grid>
                        
                </Grid>
                <Grid spacing={2} container style={{backgroundColor: "#F2C7BB",borderRadius: "10px",height:"25px"}}>
                   <p style = {{marginTop : "2px"}}>&nbsp;&nbsp;<img src="images/info.png" style = {{ height : "15px"}} alt="icon"></img>&nbsp;&nbsp;{rencentReport.report_status}</p>
                </Grid>
                {/* <img src="images/caution.jpeg" style = {{ height : "20px"}} alt="icon"></img> */}
              </div>   
            </div>    
          )
        }else if(rencentReport.report_status === "Report Generated Successfully"){
          return(
            <div className="report_filter_div1" style={{backgroundColor: "#EDEBEB",marginTop : "10px"}}>
              <div style = {{marginRight: "50px" , marginLeft: "50px"}}>
                <Grid spacing={2} container>
                  <Grid item xs>
                     <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>{rencentReport.report_type}</p>
                        <p>{a}</p>
                     </Grid>
                     <Grid item xs>
                          <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Format</p>
                             {rencentReport.format_type}
                    </Grid>
                    <Grid item xs>
                         <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Requested Time</p>
                        {rencentReport.request_time}
                    </Grid>
                    <Grid item xs style={{ display: ((new Date(rencentReport.expire_time).getTime())/1000 > new Date().getTime()/1000) ? "" : "none"}}>
                      <button style={{border:"none"}}>
                      <a href={rencentReport.generated_link} target="_blank" className="href_style" id = {rencentReport.expire_time} id= {index}>
                          <p className="work_sans_regular16" style = {{fontWeight: 'bold',color : "#0766E3"}}>Download</p>
                      </a>
                      </button>
                     
                    </Grid>
                    <Grid item xs style={{ display: (new Date(rencentReport.expire_time).getTime()/1000 < new Date().getTime()/1000) ? "" : "none"}}>
                      <button style={{border:"none"}} className = "rmBox" id = {rencentReport.expire_time} id = {index}>
                          <p className="work_sans_regular16" onClick={this.downloadClick.bind(this,rencentReport.request_time,
                                                                                                rencentReport.report_type,
                                                                                                rencentReport.format_type,
                                                                                                rencentReport.period_type,
                                                                                                rencentReport.file_name,
                                                                                                rencentReport.expire_time,
                                                                                                index,
                                                                                                )} style = {{fontWeight: 'bold',color : "#0766E3"}}>Download</p>
                     </button>
                    </Grid>  
                </Grid>
                <Grid spacing={2} container style={{backgroundColor: "#F2C7BB",borderRadius: "10px",height:"25px"}}>
                   <p style = {{marginTop : "2px"}} >&nbsp;&nbsp;<img src="images/info.png" style = {{ height : "15px"}} alt="icon"></img>&nbsp;&nbsp;{rencentReport.report_status}</p>
                </Grid>
                {/* <img src="images/caution.jpeg" style = {{ height : "20px"}} alt="icon"></img> */}
              </div>   
            </div>    
          )
        }else if(rencentReport.report_status === "Error"){
          return(
            <div className="report_filter_div1" style={{backgroundColor: "#EDEBEB",marginTop : "10px"}}>
              <div style = {{marginRight: "50px" , marginLeft: "50px"}}>
                <Grid spacing={2} container>
                  <Grid item xs>
                     <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>{rencentReport.report_type}</p>
                        <p>{a}</p>
                     </Grid>
                     <Grid item xs>
                          <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Format</p>
                             {rencentReport.format_type}
                    </Grid>
                    <Grid item xs>
                         <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Requested Time</p>
                        {rencentReport.request_time}
                    </Grid>
                    <Grid item xs>
                      <p className="work_sans_regular16" style = {{fontWeight: 'bold',color : "green"}}>Please Try Again</p>
                    </Grid>        
                </Grid>
                <Grid spacing={2} container style={{backgroundColor: "#F2C7BB",borderRadius: "10px",height:"25px"}}>
                   <p style = {{marginTop : "2px"}}>&nbsp;&nbsp;<img src="images/info.png" style = {{ height : "15px"}} alt="icon"></img>&nbsp;&nbsp;{rencentReport.report_status}</p>
                </Grid>
                {/* <img src="images/caution.jpeg" style = {{ height : "20px"}} alt="icon"></img> */}
              </div>   
            </div>    
          )
        }else if(rencentReport.report_status === "Processing") {
          return(
            <div className="report_filter_div1" style={{backgroundColor: "#EDEBEB",marginTop : "10px"}}>
              <div style = {{marginRight: "50px" , marginLeft: "50px"}}>
                <Grid spacing={2} container>
                  <Grid item xs>
                     <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>{rencentReport.report_type}</p>
                        <p>{a}</p>
                     </Grid>
                     <Grid item xs>
                          <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Format</p>
                             {rencentReport.format_type}
                    </Grid>
                    <Grid item xs>
                         <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Requested Time</p>
                        {rencentReport.request_time}
                    </Grid>
                    <Grid item xs>
                    <p className="work_sans_regular16" style = {{fontWeight: 'bold',color : "orange"}}>Generating ...</p>
                    </Grid>        
                </Grid>
                <Grid spacing={2} container style={{backgroundColor: "#F2C7BB",borderRadius: "10px",height:"25px"}}>
                   <p style = {{marginTop : "2px"}}>&nbsp;&nbsp;<img src="images/info.png" style = {{ height : "15px"}} alt="icon"></img>&nbsp;&nbsp;{rencentReport.report_status}</p>
                </Grid>
                {/* <img src="images/caution.jpeg" style = {{ height : "20px"}} alt="icon"></img> */}
              </div>   
            </div>    
          )
        }else if(rencentReport.report_status === "Report Requested") {
          return(
            <div className="report_filter_div1" style={{backgroundColor: "#EDEBEB",marginTop : "10px"}}>
              <div style = {{marginRight: "50px" , marginLeft: "50px"}}>
                <Grid spacing={2} container>
                  <Grid item xs>
                     <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>{rencentReport.report_type}</p>
                        <p>{a}</p>
                     </Grid>
                     <Grid item xs>
                          <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Format</p>
                             {rencentReport.format_type}
                    </Grid>
                    <Grid item xs>
                         <p className="work_sans_regular16" style = {{fontWeight: 'bold'}}>Requested Time</p>
                        {rencentReport.request_time}
                    </Grid>
                    <Grid item xs>
                    <p className="work_sans_regular16" style = {{fontWeight: 'bold',color : "#4F494E"}}>Requested...</p>
                    </Grid>        
                </Grid>
                <Grid spacing={2} container style={{backgroundColor: "#F2C7BB",borderRadius: "10px",height:"25px"}}>
                   <p style = {{marginTop : "2px"}}>&nbsp;&nbsp;<img src="images/info.png" style = {{ height : "15px"}} alt="icon"></img>&nbsp;&nbsp;{rencentReport.report_status}</p>
                </Grid>
                {/* <img src="images/caution.jpeg" style = {{ height : "20px"}} alt="icon"></img> */}
              </div>   
            </div>    
          )
        }
      })
      return(<div>
           {rencentReport}
      </div>   
      )  
    }
    
    
    
    ////////////////////////Recent Reports//////////////////////
    
    //////////////////////create Schedular Report//////////////////
    createSchedularReport = () =>{
      if (this.state.report_type == 32 || this.state.report_type == 35){
        var {report_type,period_type,startDate,endDate,reportFormatType,sendEmail,emailaddress,choice_value,checkbox_value,checkbox_value_forDriver,circle_type} = this.state
        //console.log("in circle type", circle_type)
      }
      else{
      var {report_type,period_type,startDate,endDate,reportFormatType,sendEmail,emailaddress,choice_value,checkbox_value,checkbox_value_forDriver} = this.state
    }
      this.hideAllDropDown()
      var start_time = startDate
      var end_time = endDate 
      if (report_type == 0) {
        this.setState({
          openAlert: true,
          message : "Please Select Report Type"
        })
        return
      }
      if (period_type == "CUSTOM") {
        start_time = CommonFunction.createServerDateTime(this.state.startDate1,this.state.sendStartTime)
        end_time = CommonFunction.createServerDateTime(this.state.endDate1, this.state.sendEndTime)
        var Difference_In_Time = this.state.endDate1.getTime() - this.state.startDate1.getTime();
        var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
        if(Difference_In_Days > 90){     
          this.setState({
            sevenDayFlag : false,   
            openAlert : true,
            message : "Not allowed to select more than 90 days"     
          })     
          return 
        }else{
          this.setState({
            sevenDayFlag : true
          })
        }
      }
      if (period_type == 0) {
        this.setState({
          openAlert: true,
          message : "Please Select Period Type"
        })
        return
      }
      if ((this.state.report_type == 32 || this.state.report_type == 35) && circle_type == 0){
        this.setState({
          openAlert: true,
          message : "Please Select circle Type"
        })
        return
      }
      if (reportFormatType == 0) {
        this.setState({
          openAlert: true,
          message: "Please Select Format Type"
        })
        return
      }
      if (report_type == 20) {
        if (checkbox_value_forDriver.length == 0){
          this.setState({
            openAlert: true,
            message : "Please Select Driver"
          })
          return
        }
      }
      var emailId = ""
      if (sendEmail){
        emailId = emailaddress
      }
      var serialNoList = []
      if (choice_value == "Vehicle Based Report"){
          if(this.state.allchecked){
            serialNoList = this.state.serialNoList
          }
          else{
            serialNoList = checkbox_value
          }   
      }
      else if (choice_value == "Group Based Report"){
          var serialNoList1 = this.state.checkbox_value_forGroup
          let uniqueChars = [...new Set(serialNoList1)]
          serialNoList = uniqueChars
      }

      if (report_type == 20) {
        serialNoList = this.state.checkbox_value_forDriver
      }
    
      var dateValidate = CommonFunction.dateValidation(start_time , end_time)
      var strArr = serialNoList.map(function(e){return e.toString()});
      if (dateValidate){
        var data = {report_type : report_type,
          period_type : period_type,
          circle_type : circle_type,
          format_type : reportFormatType ,
          email_id : emailId,
          report_start_time : start_time,
          report_end_time : end_time,
          serial_number_list : strArr,}
        //console.log(data)
        $('#processing').show()
        ApiUrlCall.createSchedularReport(JSON.stringify(data)).then(response => {
          if (response.data.response_code === 200 && response.data.is_success === true) {
            this.setState ({
              //nextCount : 0,
              list : [],
            },() =>{
              this.getRecentReports()
              $("#button").show()  
            })
            $('#processing').hide()
          }     
        }).catch(error => {
          console.log(error, "error while creating schedular report");
        })
      }else{
        $('#processing').hide()
          this.setState({
            openAlert : true,
            message : "Kindly select valid date"
          })
      }  
    }
    
    //////////////////////create Schedular Report END ///////////////

    //////////////////////Driver Report ////////////////////////
    getDriverList = () => {
        var data = {}
        ApiUrlCall.driverDetailsData().then(response => {
            if(response.status === 200){
            this.setState({
                driverNameList: response.data,    
            })
          }
        }).catch(error => {
          console.log(error, "error while fetching vehicle list");
        })
      }

    onChangeDriver = (e) =>{
        // current array of options
      const options = this.state.checkbox_value_forDriver
      let index
      let {counter} = this.state
    
      // check if the check box is checked or unchecked
      if (e.target.checked) {
        // add the numerical value of the checkbox to options array 
        const list = e.target.value.split(",")     
        //console.log(e.target.value)
        //this.forceUpdate()
        this.state.checkbox_value_forDriver.push(...list)
    
        // $('#vehicles_select').slideUp(200)
    
      } else {
        // or remove the value from the unchecked checkbox from the array      
        //index = options.indexOf(+e.target.value)
        const list = e.target.value.split(",")      
        //options.splice(index, 1)
        //this.forceUpdate()
        list.map(e =>{
          index = this.state.checkbox_value_forDriver.indexOf(e)
          this.state.checkbox_value_forDriver.splice(index,1) 
        })
      }
    
      // sort the array
      options.sort()
    
      // update the state with the new array of options
      // this.setState({ options: options })
    }

    searchVehicle= (e) => {
      let input = document.getElementById('searchbar').value
      input=input.toLowerCase();
      let x = document.getElementsByClassName('vehicleforvehicle');
      
      for (var i = 0; i < x.length; i++){
        if (!x[i].innerHTML.toLowerCase().includes(input)) {
            x[i].style.display="none";
        }
        else {
            x[i].style.display="";               
        }
      }
    }

    // logReportType() {
    //   console.log("Current Report Type:", this.state.report_type);
    // }


    //////////////////////Driver Report End //////////////////////
    
    
      render(){
        const { t } = this.props
        if (this.state.vehicle_name) {
          let counter = 0
    
          var vehicledataname = this.state.vehicle_name.vehicle_list.map(vehicledataname => {
            this.state.serial_number_is = vehicledataname.serial_number
            if(vehicledataname.is_expired == false){
              return (
                <div class="vehicleforvehicle">
                  <Grid container className="select_opt_row" >
                    <Grid item xs={2}>
                      <input type="checkbox"
                        value={vehicledataname.serial_number}
                        onChange={this.onChange.bind(this)} name="vehicle_tick" />
                    </Grid>
                    <Grid item xs={10}>
                      <p className="p_margin0">{vehicledataname.name}</p>
                    </Grid>
                  </Grid>
                </div>
              )
            } 
          })
        }

        if (this.state.driverNameList) {
            let counter = 0
      
            var vehicledrivername = this.state.driverNameList.map(vehicledrivername => {
              this.state.serial_number_is = vehicledataname.serial_number
              return (
                <Grid container className="select_opt_row">
                  <Grid item xs={2}>
                    <input type="checkbox"
                      value={vehicledrivername.id}
                      onChange={this.onChangeDriver.bind(this)} name="vehicle_tick" />
                  </Grid>
                  <Grid item xs={10}>
                    <p className="p_margin0">{vehicledrivername.driver_name}</p>
                  </Grid>
                </Grid>
      
              )
      
            })
        }
    
        if (this.state.checkbox_value.length > 0) {
          if (this.state.checkbox_value.length == 1) {
            var lableText = this.state.checkbox_value.length + " " + t('ars.sum.v1')
          } else {
            var lableText = this.state.checkbox_value.length + " " + t('ars.sum.v2')
          }
        } else {
          if (localStorage.getItem("group") == 32){
          var lableText = t('All DG')
        }
        else{
        var lableText = t('ars.sum.v3')
        }

        }
    
      //   if (this.state.checkbox_value_forGroup.length > 0) {
      //     console.log(this.state.checkbox_value_forGroup,"iiiiiiiiiiiiii")
      //     var lableText1 = this.state.checkbox_value_forGroup.length + " " + "Group"
      // } else {
      //   var lableText1 = "All Groups"
      // }
    
        if (this.state.vlcGroupDataList) {
          let counter = 0
          var groupdataname = this.state.vlcGroupDataList.map(groupdataname => {
            return (
              <Grid container className="select_opt_row">
                <Grid item xs={2}>
                  <input type="checkbox"
                    value={groupdataname.serialNoList}
                    onChange={this.onChangeForGroup.bind(this)} name="vehicle_tick" />
                </Grid>
                <Grid item xs={10}>
                  <p className="p_margin0">{groupdataname.groupName}</p>
                </Grid>
              </Grid>
            )
          })
        }
    
          return(
          <div>
            <Navbar />
            <div className="scheduler_report_div" style={{ paddingLeft: "100px", paddingRight: "100px", marginBottom: "30px" }}>

              <div>
                <h3 className="page_header">
                  <Link to="reports"><img src="images/arrow.png" alt="icon"></img></Link>
                  <p style={{ display: "inline",marginBottom : "20px"}}>&nbsp;&nbsp;Scheduler Report</p>
                </h3>
              </div>
            <div className="report_filter_div">
              {/* <div className = "journey_detail_div"> */}
              <div style = {{marginRight: "50px" , marginLeft: "50px"}}>
                  {/* <p className="work_sans_regular16">You can generate new reports or download from the list of recently generated reports</p> */}
                  <Grid spacing={2} container>
                    <Grid item xs={3}>
                      <p className="work_sans_regular16">Select Report Type</p>
                      {this.returnReportType()}
                      {/* <div style={{ display: (this.state.showCalender) ? "" : "none" }}>
                          <p>{this.state.date}</p>
                      </div> */}
                    </Grid>
                    {/* {this.logReportType()} */}
                    { (this.state.report_type == 32 || this.state.report_type == 35) && (
                  <Grid item xs={3}>
                  <p className="work_sans_regular16">Select Circle</p>
                  {this.returnCircleType()}
                 </Grid>
                   )}
                   { (this.state.report_type == 32 || this.state.report_type == 35) && (
                  <Grid item xs={3}>
                  <p className="work_sans_regular16">Select Area</p>
                  {this.returnAreaType()}
                 </Grid>
                   )}
             

                  </Grid>
              </div>
              {/* </div>  */}
            </div> 

            {/* Driver Report Start */}
            <div style={{ display: (this.state.report_type == 20) ? "" : "none"}} >
            <div className="report_filter_div" style = {{ marginTop : "10px"}} >
                <div style = {{marginRight: "50px" , marginLeft: "50px"}}>
                <Grid spacing={2} container>
                    <Grid item xs = {5}>
                      <p className="work_sans_regular16">{'Select Period'}</p>
                      {this.returnPeriodType()}
                      <div style={{ display: !(this.state.showCalender) ? "" : "none" }}>
                          <p>{this.state.date}</p>
                      </div>
                    </Grid>
                      {this.showStartCalender()}
                      {this.showEndCalender()}
                  </Grid>
                  {/* Select Format Start */}
                  <Grid spacing={2} container>
                    <Grid item xs={5}>
                      <p className="work_sans_regular16">Select Format</p>
                      {this.returnFormatType()} 
                    </Grid>
                    <Grid item xs={5}  >
                      <p className="work_sans_regular16">Email Report To</p>
                      <label class="radio-inline cj_w_header" >
                          <input type="checkbox" id="emailto" name="emailto" onClick={this.sendEmailto}></input>&nbsp;&nbsp;{this.state.emailaddress}
                      </label>
                    </Grid>  
                  </Grid>
                  {/* Select Format End */}
                  {/* Driver  name dropdown */}
                  <Grid spacing={2} container>
                      <Grid item xs ={4}>
                          <label className="work_sans_regular16">{t('ars.dr.driver')}</label>
                          <div style={{ position: "relative" }} onClick={this.dropDownClick}>
                              <div className="summary_report_filters">
                              <label>
                              All Drivers
                              {/* {this.state.chckebox_value.length <= 0 ? "Select Drivers" : this.state.chckebox_value.length +  " Selected"} */}
                              </label>

                              </div>
                              <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
                          </div>
                          <div className="all_region_dd">
                              <div className="dropdown_parent" id="vehicles_select">
                              {vehicledrivername}                  
                              </div>
                          </div>
                      </Grid>
                  </Grid>
                  {/* Driver name drpdown end */}
                  <Grid spacing={2} container>
                    <Grid item xs = {2} style = {{marginTop : "10px"}}>
                      <button className="btn orange-btn btn-block" style={{ marginTop: "6px" }} onClick={this.createSchedularReport}>{t('ars.generate')}</ button>
                    </Grid>
                  </Grid>
                </div>
            </div>
            </div>
            {/* Driver REport End */}

            
            <div style={{ display: (this.state.disable) ? "" : "none"}} >
            <div className="report_filter_div" style = {{ marginTop : "10px"}} >
                <div style = {{marginRight: "50px" , marginLeft: "50px"}}>
                <Grid spacing={2} container>
                    <Grid item xs = {5}>
                      <p className="work_sans_regular16">{'Select Period'}</p>
                      {this.returnPeriodType()}
                      <div style={{ display: !(this.state.showCalender) ? "" : "none" }}>
                          <p>{this.state.date}</p>
                      </div>
                    </Grid>
                      {this.showStartCalender()}
                      {this.showEndCalender()}
                  </Grid>
                  {/* Select Format Start */}
                  <Grid spacing={2} container>
                    <Grid item xs={5}>
                      <p className="work_sans_regular16">Select Format</p>
                      {this.returnFormatType()} 
                    </Grid>
                    <Grid item xs={5}  >
                      <p className="work_sans_regular16">Email Report To</p>
                      <label class="radio-inline cj_w_header" >
                          <input type="checkbox" id="emailto" name="emailto" onClick={this.sendEmailto}></input>&nbsp;&nbsp;{this.state.emailaddress}
                      </label>
                    </Grid>  
                  </Grid>
                  {/* Select Format End */}
                  
                  <Grid spacing={2} container>
                    {/* Select Choice */}
                        <Grid item xs ={4}>
                          <label className="work_sans_regular16">Select Choice</label>
                          {/* <p>Select Choice</p> */}
                          <div style={{ position: "relative" }} onClick={this.dropDownClick}>
                          <div className="summary_report_filters">
                            <label style={{ width: "100%"}}>{this.state.choice_value}</label>
                          </div>
                          <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
                        </div>
                          <div className="all_region_dd">
                            <div className="dropdown_parent" id="vehicles_select">
                            <Grid container className="select_opt_row">
                                <Grid item xs={2}>
                                  <input type="checkbox"
                                    id = "option1"
                                    defaultChecked={this.state.checked}
                                    value={this.state.option_type[0]}
                                    onChange={this.choiceChange.bind(this)} 
                                    name="vehicle_tick" />
                                </Grid>
                                <Grid item xs={10}>
                                  <p className="p_margin0">{this.state.option_type[0]}</p>
                                </Grid>
                            </Grid>
                            <Grid container className="select_opt_row">
                              <Grid item xs={2}>
                                <input type="checkbox"
                                  id = "option2"
                                  value={this.state.option_type[1]}
                                  onChange={this.choiceChange.bind(this)} 
                                  name="vehicle_tick" />
                              </Grid>
                              <Grid item xs={10}>
                                <p className="p_margin0">{this.state.option_type[1]}</p>
                              </Grid>
                            </Grid>
                            </div>
                        </div>
    
                      </Grid>
                    {/* Select Choice END */}
    
                    {/* Vehicle List */}
                    <Grid item xs ={4} style={{ display: (this.state.choice_value === "Vehicle Based Report") ? "" : "none" }}>
                      <label className="work_sans_regular16">{t('ars.sum.v2')}</label>
                      {/* <div className="input_div" onClick={this.showVehicles}></div> */}
                      <div style={{ position: "relative" }} onClick={this.dropDownClick}>
                        <div className="summary_report_filters">
                          <label>{lableText}</label>
                        </div>
                        <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
                      </div>
                      <div className="all_region_dd">
                        <div className="dropdown_parent" id="vehicles_select" >
                        <Grid item xs>
                          <input id="searchbar" onChange={this.searchVehicle} type="search"
                                name="search" placeholder="Search ..."  ref={this.inputRef}/>
                        </Grid>
                        <Grid container className="select_opt_row">
                          <Grid item xs={2}>
                            <input type="checkbox" id="all_vehicle" name="vehicle_tick" 
                            onChange={this.allVehicle} />
                          </Grid>
                          <Grid item xs={10}>
                            <p className="p_margin0">{t('ct.all.text')}</p>
                          </Grid>
                        </Grid>
                          {vehicledataname}                  
                        </div>
                      </div>
                    </Grid>
                    {/* Vehicle List END  */}
                    {/* Group List Start */}
                    <Grid item xs={4} style={{ display: (this.state.choice_value === "Group Based Report") ? "" : "none" }}>
                      <label className="work_sans_regular16">Groups</label>
                      {/* <div className="input_div" onClick={this.showVehicles}></div> */}
                      <div style={{ position: "relative" }} onClick={this.dropDownClick}>
                        <div className="summary_report_filters">
                          <label>All Groups</label>
                        </div>
                        <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
                      </div>
                      <div className="all_region_dd">
                        <div className="dropdown_parent" id="vehicles_select" id = "456">
                          {groupdataname}                  
                        </div>
                      </div>
                    </Grid>
    
                    {/* Group List End */}
    
                  </Grid>
    
                  <Grid spacing={2} container>
                    <Grid item xs = {2} style = {{marginTop : "10px"}}>
                      <button className="btn orange-btn btn-block" style={{ marginTop: "6px" }} onClick={this.createSchedularReport}>{t('ars.generate')}</ button>
                    </Grid>
                  </Grid>
                </div>
            </div>
            </div>

            <p style = {{fontWeight: 'bold',fontSize : "20px",marginTop : "20px"}}>Recent Reports</p>
            {this.showRecentReport()}
            <button style={{ marginTop: "15px", width : "100px", height : "30px",marginLeft : "900px",fontWeight: 'bold',}} id = "button" onClick={this.getRecentReportsonclick}>Load More</ button>

            </div>
            
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              autoHideDuration={4000}
              onClose={(e, reason) => {
                if (reason === 'clickaway') {
                  return;
                }
                this.setState({ openAlert: false })
              }
              }
              open={this.state.openAlert}
              message={this.state.message}
            />
          </div>
          )
      }

}


export default withTranslation()(Scheduler_report);
